import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_OpenHAB_Tutorials/PrimaryBox';
import PrimaryBox from 'components/Advanced_User/openHABian_on_Raspberry_Pi/PrimaryBox';
import TimeLine from 'components/Products/CommentList/MqttIFTTTList';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Raspberry Pi & openHABian",
  "path": "/Advanced_User/openHABian_on_Raspberry_Pi/",
  "dateChanged": "2018-09-28",
  "author": "Mike Polinowski",
  "excerpt": "The open Home Automation Bus (openHAB) is an open source, technology agnostic home automation platform which runs as the center of your smart home. openHABian aims to provide a self-configuring Linux system setup specific to the needs of every Raspberry Pi user. Control your INSTAR IP camera with your home automation system. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD",
  "image": "./AU_SearchThumb_openHABian.png",
  "social": "/images/Search/AU_SearchThumb_openHABian.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_OpenHAB2_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "OpenHAB"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <SEOHelmet title='Raspberry Pi & openHABian' dateChanged='2018-09-28' author='Mike Polinowski' tag='INSTAR IP Camera' description='The open Home Automation Bus (openHAB) is an open source, technology agnostic home automation platform which runs as the center of your smart home. openHAB software integrates different home automation systems, devices and technologies into a single solution. It provides uniform user interfaces, and a common approach to automation rules across the entire system, regardless of the number of manufacturers and sub-systems involved. Control your INSTAR IP camera with your home automation system. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD' image='/images/Search/AU_SearchThumb_openHABian.png' twitter='/images/Search/AU_SearchThumb_openHABian.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/openHABian_auf_Raspberry_Pi/' locationFR='/fr/Advanced_User/openHABian_on_Raspberry_Pi/' crumbLabel="OpenHAB" mdxType="BreadCrumbs" />
 
    <NavButtons mdxType="NavButtons" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h1 {...{
      "id": "raspberry-pi--openhabian",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#raspberry-pi--openhabian",
        "aria-label": "raspberry pi  openhabian permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Raspberry Pi & openHABian`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#install-openhabian-on-a-raspberry-pi"
        }}>{`Install openHABian on a Raspberry Pi`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#openhab--node-red-tutorials"
        }}>{`OpenHAB & Node-RED Tutorials`}</a></li>
    </ul>
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <TimeLine mdxType="TimeLine" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Use a Raspberry Pi to control your INSTAR Network Camera. The OpenHAB Linux distribution `}<a parentName="p" {...{
        "href": "https://www.openhab.org/docs/installation/openhabian.html#openhabian-hassle-free-openhab-setup"
      }}>{`openHABian`}</a>{` aims to provide a self-configuring Linux system setup specific to the needs of every Raspberry Pi user. ## Install openHABian on a Raspberry Pi Following the `}<a parentName="p" {...{
        "href": "https://www.openhab.org/docs/installation/openhabian.html#raspberry-pi"
      }}>{`Quick Start Guide`}</a>{` you first have to `}<a parentName="p" {...{
        "href": "https://github.com/openhab/openhabian/releases"
      }}>{`download the newest version`}</a>{` of the openHABian the `}<a parentName="p" {...{
        "href": "https://etcher.io/"
      }}>{`Etcher`}</a>{` tool to prepare your SD card:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1bebec1057196a45a42c53e0f0bc37eb/e9beb/OpenHabian_00.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "97.82608695652175%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADfUlEQVQ4y3WRy28bVRTG/bewhTRlgVgWnCZ+zfvpGb+dJk1bQiKSRg2u6IKY8NggsaMgJRQQSyRUmpa0LKFQFEjoivqRhDpNbM+MPe97584gP6o2ojn6dO75pPu75+jckOm5qudA33dc33SQakAHIAcg20WDwnKRDZBhI1WHAPrBcxGyatXW9EynvldrWo2mVT0wD1W73XFaHfdAdY9UZ+/IbLStI9V5orimi47B3uOGPr9kHx5qumZ0OwgCCFwPAs9DAPoehB7sWQhB8L8IDQ7PQ221o3YMhIKTwvcD/wWwHwAX6BbUDNQxIfIHERzTi5571lk3bN1wADy59UkwAEDvaHq3Y9uWZZqWaTm2bTnQcpHleAOZ9jCbPYtciIaw7YDKv0q1oVUbWkvVurqhm0bXgpoVdB1ftQPF9FXTV61eVgyk6NC0vSEMoXe0f6A+fgLULup2oar2pfSkKF6/6GVN8ZS2bxjHxg583/1r2/xjC2w/7D74s7pxt3b73u6dn6sbm5VbP1VubT76cbN+515l4+6jm7eNre3B8oew5tjbjb0Hu5Xfa//c363/Ut+9X9/9bW//1/3DrYPmTrO102o9bLd3Ws2/m82KodVdo2brIdT/2c9ufv/S4sVTH5RGyu/2tLI8Ul5+pbw8Ui6Nrl4dXS2dXi2N9nX6w6sjK70Lr398LeR5nh8En3+5PnYmSuN8YoKMjxNEjMGjNBGjsQgVCSfwGINFaSxK41E6Pk7gUZpKcLKYC7kAONC9vnYjHCFwJillz2UK53EmSQsZkpXFVOHi3CJG9ywlZDA2lSrM4Fw6TooknxqO/cX612MTRARj35pfWipdi2AsJ2YxSswVL3z0yacxgmeEDMvJFE6fL06xJIvhLC1khvD1r76ZCMev4NwinXyH5C9jLImxswlmgZUvkfwCKxUiiTP5pdH3N06t/PByefONyfdYVniu89lElpGyUjErTaZpkSCFFCHkuUxOOpdnZQFno0JxrHBlLZIuSPPh5BTDyc/g8DiOMVKcFOKUGCOFBCnE+0r0bZxM4gRHY8T6q6/NhsejJMfw6afw2o03zyYoRiIokSBFipEYPj20lEhSIs9zNCdjlBRmUzFGxgiB4p4ubO3b7yI4x6XyTDJLixk5Pz0zu5CZvMDKOS5d4NP5ubns9KW3+XQxKWWFVJ5P5ZO5qf8ArYBYKbVpzOkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1bebec1057196a45a42c53e0f0bc37eb/e4706/OpenHabian_00.avif 230w", "/en/static/1bebec1057196a45a42c53e0f0bc37eb/d1af7/OpenHabian_00.avif 460w", "/en/static/1bebec1057196a45a42c53e0f0bc37eb/b6582/OpenHabian_00.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1bebec1057196a45a42c53e0f0bc37eb/a0b58/OpenHabian_00.webp 230w", "/en/static/1bebec1057196a45a42c53e0f0bc37eb/bc10c/OpenHabian_00.webp 460w", "/en/static/1bebec1057196a45a42c53e0f0bc37eb/87ca7/OpenHabian_00.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1bebec1057196a45a42c53e0f0bc37eb/81c8e/OpenHabian_00.png 230w", "/en/static/1bebec1057196a45a42c53e0f0bc37eb/08a84/OpenHabian_00.png 460w", "/en/static/1bebec1057196a45a42c53e0f0bc37eb/e9beb/OpenHabian_00.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1bebec1057196a45a42c53e0f0bc37eb/e9beb/OpenHabian_00.png",
              "alt": "Raspberry Pi & openHABian",
              "title": "Raspberry Pi & openHABian",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Insert the SD card into the Raspberry Pi, connect Ethernet and power and wait approximately 15-45 minutes for openHABian to be installed. As can be seen in the screenshot below, the whole process can take a lot longer on an older Pi - use your SSH terminal (Windows user can use the free Terminal from `}<a parentName="p" {...{
        "href": "https://mobaxterm.mobatek.net/download.html"
      }}>{`Mobaterm`}</a>{`) to connect to your Pi (`}<strong parentName="p">{`default login: user = openhabian and password = openhabian`}</strong>{`) and check the installation progress:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/01d65e5ec424211f63d5e779f8f5db1f/e9beb/OpenHabian_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "81.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADRElEQVQ4y1WUWXPaWBCFlUpmScrxBsbEsU0IYDax7xIISQjQviKbAc/y///EmemGl3m4JZWq73f7nNNXQt2OUDQc/DBc/Fx7+GF4aLoxCisbl5KO7NzAo2riUbWQmWnIK1sUVh6ykoGbqYbbqcbfvw5kyId/ILT9Hep2iBczRMOJIHoJFn+8o+5EaPsJr06wg+hGaLohnnUL3xQdl2MZv3Ul/NqR8KU/x4fGAP3kAKETvKJmR6ha/weKboxemDKIoV6MuhWgvHHxTVGRnS1xPdFww0vBR3GEXnKE0PJTvJgBQ2tWiKoZYL4/8jt1ScDK1sfPtYX7+QoZiWTqyEorBmZmOjIzBZ9aI3TjA4R2kLJcApKfL2cgPfNrFyXTR9OJGU415a2HmuWz9DtJxe1ExvWYJA/Ri1lyiqaboOntUFp7qGwDTF8PqJ8toEPIBgqK6lo+1ZFsDVlJwb1M8lV8EkcYpkcI3fCVN1IhdUXQYbJnT0WXAkkZVuNpsFBcWXjWTNzPDeQXBh6XBu4kHb93Rpjs/4LQj954w8mvmEGT9MAy6ZD2edGhDTtAx49RswLu8EnbIjOllGcQ6gP0kveThw0nPnsYsjySTKFUDAcvWx+VjcsgSrm4cpCTVWRmS57B28kClyMFQr2PTrg/zWGLO4jPvsUM7EZvEJ0EDTtE14/QD3eomR5KhoNHdYuCvkFBN/GgrHE7XeBDo4dOdITQoM6ciEMhudQpAZt2xP71wxSjKIFoB6isbTTtEA/KClfjKT53ZVwM5rgcKvilNcX47W8IFEJONlDe+Hz1nlSTU/y+3OJOWuFe0pCXNeQkDTdjBXczSlfH9VjFxXDOt+SCgO0ZRumf5OErp0tekdyqGWL69g4aePK37Ya8SG7T8lE3fZTWFnKSiuuxjK/DJd/pj+IYvfhIHqZ8Oypsvsfvk90eVRpgM4Bo++h4IXpBgpZDsh0enfLGQ36uIycvcTUiyZNTh3TxKV0a4BMwhEQdejtQ903bxyBMMIx2DKakqa68sfCgbJCfG6eUqz0Md+8QKmaE4tpH2QzZN/pNid4Oz7rNG0orE0Vtg+8LBU/LBQdS0G3k5mtcjVV87sn40pMZOPjvb/MvZ+odogD86dcAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/01d65e5ec424211f63d5e779f8f5db1f/e4706/OpenHabian_01.avif 230w", "/en/static/01d65e5ec424211f63d5e779f8f5db1f/d1af7/OpenHabian_01.avif 460w", "/en/static/01d65e5ec424211f63d5e779f8f5db1f/b6582/OpenHabian_01.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/01d65e5ec424211f63d5e779f8f5db1f/a0b58/OpenHabian_01.webp 230w", "/en/static/01d65e5ec424211f63d5e779f8f5db1f/bc10c/OpenHabian_01.webp 460w", "/en/static/01d65e5ec424211f63d5e779f8f5db1f/87ca7/OpenHabian_01.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/01d65e5ec424211f63d5e779f8f5db1f/81c8e/OpenHabian_01.png 230w", "/en/static/01d65e5ec424211f63d5e779f8f5db1f/08a84/OpenHabian_01.png 460w", "/en/static/01d65e5ec424211f63d5e779f8f5db1f/e9beb/OpenHabian_01.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/01d65e5ec424211f63d5e779f8f5db1f/e9beb/OpenHabian_01.png",
              "alt": "Raspberry Pi & openHABian",
              "title": "Raspberry Pi & openHABian",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Once the installation is completed, restart your Pi and log-in again:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/da55bbdb9fa5b44145b43fd1b21ab46a/e9beb/OpenHabian_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.04347826086956%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC10lEQVQozy2QWZOiSBSFGVlU0MJ9QaW6unumY0oBZcuFTBKSBGUpq146et7m//+LCasn4jzch/vde86RhrRRUKXh0qDlIKkmmZjxYpIJk4pxUo5wOQClBsoBLP/wc+nMHvKo5FLJJdKItU95Z/I3M6sn6DrB1xEUesTVKOuFTPJ/b6dazHvBbzh9wB6R3ERaZM2fbz+Df/796/5rSqp5Wm/5+zp/m5B6SuslaxbpbUavJiq1gCkXqlyI4hHZS2QXS1ZafS2756KZJ8WaCrtovlzvh6K1ebPP653o1uw2AbkJ8qFPNQ9rHlZcpDhQPkFpgYoNqRaomH8OSyxMWJiQzwCfIfGEhAm4fqH6hWouVl2sOEj9JOUTkGzefa/uX8TbPmts3tq8feadnbe7tH4pukNWP+fNKhHDczJw0dBDmov7HlIfz4H043p/KVo7r59581V03x5qftR3i5YLxLe0XCKuX5KhBzUH9l2ouVB1Yd9D8imW7KJ9KbqXov0mukN22xCxJYUFi1XETT8dBXQUUPVh9X99pgXKCfReI8nKblZaHdhtQ0qLlFsiTJDNL8RC5RqVkzibxGwBuRmxScymcWZG7HHRp6OQSvo5Mc54AbNpzIbeI5XhE92n/TPWAzq4JKrzMDk4o76LNAeqDlAdoDlQPsbSwEULmO+oWKF8m/ANEStyXeJyjvg6KZa4mCHePz9K6ntIcdFnz7FyiuVjJOkeXsRsBdkhFTuS7Wlm5/UurayEj30y8onhE9VFsgO1S2LE6SAgsod6DuwdgbSCbAWolbAdZvuE7gnbU26RfJukK5iuEZuBdAqYGad6SIwQGyEyQjwMsOwiyW06r2nOTfta1n+Lm3NrT9fmtWqOdRt+fATvH277bufVlvJxhMcRGIWREcZ6CBUPSTtaWEm+J8WeiDXMbFocr2QD0RbSDSRWwuaAmGEyBdQI0TgCT1E0CmLdR5oL/gMlQ6FbLtiwKwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/da55bbdb9fa5b44145b43fd1b21ab46a/e4706/OpenHabian_02.avif 230w", "/en/static/da55bbdb9fa5b44145b43fd1b21ab46a/d1af7/OpenHabian_02.avif 460w", "/en/static/da55bbdb9fa5b44145b43fd1b21ab46a/b6582/OpenHabian_02.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/da55bbdb9fa5b44145b43fd1b21ab46a/a0b58/OpenHabian_02.webp 230w", "/en/static/da55bbdb9fa5b44145b43fd1b21ab46a/bc10c/OpenHabian_02.webp 460w", "/en/static/da55bbdb9fa5b44145b43fd1b21ab46a/87ca7/OpenHabian_02.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/da55bbdb9fa5b44145b43fd1b21ab46a/81c8e/OpenHabian_02.png 230w", "/en/static/da55bbdb9fa5b44145b43fd1b21ab46a/08a84/OpenHabian_02.png 460w", "/en/static/da55bbdb9fa5b44145b43fd1b21ab46a/e9beb/OpenHabian_02.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/da55bbdb9fa5b44145b43fd1b21ab46a/e9beb/OpenHabian_02.png",
              "alt": "Raspberry Pi & openHABian",
              "title": "Raspberry Pi & openHABian",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If you see the same Error message as in the screenshot above `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`FireMotD: Info: No FireMotD ExportFile detected`}</code>{` follow `}<a parentName="p" {...{
        "href": "/en/Advanced_User/openHABian_on_Raspberry_Pi/No_FireMotD_ExportFile_detected/"
      }}>{`this Guide`}</a>{` to get FireMotD set up. Give your Raspberry Pi some time to boot then access the OpenHAB web interface under `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://openhabpi:8080`}</code>{` (or alternatively via its local IP address) and choose the `}<strong parentName="p">{`Standard Package`}</strong>{` to get started.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/59d3e5ec57e9880420ea6f7d3a710489/e9beb/OpenHabian_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACsElEQVQoz4WKa0hTYRyHX6gIEkTDyr6UWUkXQYksC0u6qSWT2YIINxUTBGsE3TQv2dQY3pZuum5a2qpNl64idZpUXvCL5AclKoVybmeXs3nZ5Wzn9p43ZiZEgx6eD8/vzx+wd2JpaTx9axsj2cvlrEYZAImWFP5f4B1q9Y6+IAebfYPNdH890yfz9VT7uqvJ3lpKX0f21FD6WqZfRuvr/PbVLcdSAwy3f58x/bA4jA6nweGeNs9NY3aD3eXwUlMm+wzu+jqLmxcJGiHqb0mEgMNmNRp+mk1GzDSLzRrMmBHDMMyEWcxmDMOsVjNutVjNGG61OHCb3Wa126z+ttl8hAeQFMWwLEmRJEn5fD6GoTmEIIcQQhTN0AyL/gAh5DhuZbIsC4Y/fXykbOrqUHdp279MTgwNj7ZptB26t8+eq8c+j4+MjLRr2nU6nfqlZmJiUq/va2tVdajfqFWdU9+mgKTwWlrifnGm4GKWsEEuz84Tg/DIsEMnVkVEXy8pz83KjorcsnvXztCQ9aXFZacTstatCdocsmMt2CivVwJlTXllaWFLq6pYek/X3SeWyLafyY7LKYw6l1+meHqzSJKSfiEnX3wgMel2RVVq8qXUI5czTxfEhGeolO/BQFXe6yvHBhQF6qv8MW1jWV7G0YhQ/vHDiVuDlXeLnhTl5uzbUJIWI4oOetUgkaXEFWwKq9yTKgyOHWzRAdgjRdKD8H46qoqH7yrY3krUmIyazyPFSfhBATtvIPkp9CAdyZPg0ENOk4+aktDjs6g+gR3XgnmCtnk5nEB2EnkhJFjohsgDkYdDXhb6LxwiuOVJQG5l+hgWuJ2LC3O4c2HetTDnci663J6FRZfb7WEokqEphqL88dt/JiC8gSGWWImAgLRA8Hg8gUAgEomEQiGfz+fxeAHffgHS/0hKqfWPAQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/59d3e5ec57e9880420ea6f7d3a710489/e4706/OpenHabian_03.avif 230w", "/en/static/59d3e5ec57e9880420ea6f7d3a710489/d1af7/OpenHabian_03.avif 460w", "/en/static/59d3e5ec57e9880420ea6f7d3a710489/b6582/OpenHabian_03.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/59d3e5ec57e9880420ea6f7d3a710489/a0b58/OpenHabian_03.webp 230w", "/en/static/59d3e5ec57e9880420ea6f7d3a710489/bc10c/OpenHabian_03.webp 460w", "/en/static/59d3e5ec57e9880420ea6f7d3a710489/87ca7/OpenHabian_03.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/59d3e5ec57e9880420ea6f7d3a710489/81c8e/OpenHabian_03.png 230w", "/en/static/59d3e5ec57e9880420ea6f7d3a710489/08a84/OpenHabian_03.png 460w", "/en/static/59d3e5ec57e9880420ea6f7d3a710489/e9beb/OpenHabian_03.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/59d3e5ec57e9880420ea6f7d3a710489/e9beb/OpenHabian_03.png",
              "alt": "Raspberry Pi & openHABian",
              "title": "Raspberry Pi & openHABian",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Congratulation, you have successfully installed OpenHAB 2 on your Raspberry Pi!`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f81dd42a88e64265719d63d0c4686a31/e9beb/OpenHabian_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "68.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC5klEQVQoz3XOa0hTYRgH8PM1goiyG35QC4nKRDRxhBfEvKKVOC+FBiIipJAUal5mG3PqNtc8XkpzsbwjaYmX1OVlNz27ejcVM63Qikp3zuY5747b3tAPBoV/fjw8/w8PPEhUXEJkXCIzJZWZkhoQHs0IifQPjwmIiGGERPgFh18LuvEPn8CQC1d93Ty8Xa94IdXJEe9Y91eaxUuNollJ+Vx9yQdJ2ZJU8LFJtNJY8b9lKf9lWhKaEFLJDEJgUbQ9J5TOCaXzo3Zzw+jYU3Ssk/X2SXDrxGHs8WdgwjmY6Ixsa2RbE4MmbJDQygjN0La6f0vdZ9YMkfphyjBC6ocPHFSLVmbRykj9MIIDmti1E7sO0gF/WkjTrt1E261wLzb4N47/qhVCpLdJ0igub6sVt1SL5oz6bml9ayW/tUrQigrmdFjbi+ctNWj9U7H0Wb1OoxdXNqBoQ4WoTiJpI0w4gorF+bm5XA67lMtVq1QiIb+4IJ/NKuKyn6jGRgXFBQJeCZvF4peWKRVyLoddxuOxi4urURTHcUQ7PTusxpQ6AzY1u76xqTRMjYxrxjCdyji9trau6uzQvJf1qoxv5cbVz1+xqTn9/KJ6cnZqaWWHJBFrh4iSckBLGf2KQxlHQQuPbi6BHULYyqO0sm3OPYswYwN9vIqyCGyQqHpgbijCq7LNUjZFmJBPtYWLrKRlTvJSIfP7yGsDJ0Obd1eTnzz+kPlrYpDuqqQ6Kuh2vq1LDGaUoIkL2oWgqQR0osCMI8KaupxHmYV5WUV5WQMDfed9Ao67XXZy9zzi7K7AtBDCHZudgpByOIDNBiAEEFL7E1ityJhc/qa7p7unr6d/QGecdLnkc8zV8+xFn6OnXRWqcQjtOzgOLOY95A6wEPs7sQdQyO8F46Ze8W1y/IdB+WVhJszXlxERH8y47ufmoscwCCFJkuCQIHThTTrdi84OpNM8qD6JOcvfku5tyWSY77hR89jee4cf/wFSCTyW5FaoXgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f81dd42a88e64265719d63d0c4686a31/e4706/OpenHabian_04.avif 230w", "/en/static/f81dd42a88e64265719d63d0c4686a31/d1af7/OpenHabian_04.avif 460w", "/en/static/f81dd42a88e64265719d63d0c4686a31/b6582/OpenHabian_04.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f81dd42a88e64265719d63d0c4686a31/a0b58/OpenHabian_04.webp 230w", "/en/static/f81dd42a88e64265719d63d0c4686a31/bc10c/OpenHabian_04.webp 460w", "/en/static/f81dd42a88e64265719d63d0c4686a31/87ca7/OpenHabian_04.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f81dd42a88e64265719d63d0c4686a31/81c8e/OpenHabian_04.png 230w", "/en/static/f81dd42a88e64265719d63d0c4686a31/08a84/OpenHabian_04.png 460w", "/en/static/f81dd42a88e64265719d63d0c4686a31/e9beb/OpenHabian_04.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f81dd42a88e64265719d63d0c4686a31/e9beb/OpenHabian_04.png",
              "alt": "Raspberry Pi & openHABian",
              "title": "Raspberry Pi & openHABian",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br /><br />
    <ul>
      <li parentName="ul">
        <Link to="/Advanced_User/openHABian_on_Raspberry_Pi/OpenHAB_Setup/" mdxType="Link">OpenHAB Setup</Link>
      </li>
      <li parentName="ul">
        <Link to="/Advanced_User/openHABian_on_Raspberry_Pi/Node-RED_Setup/" mdxType="Link">Node-RED Setup</Link>
      </li>
      <li parentName="ul">
        <Link to="/Advanced_User/openHABian_on_Raspberry_Pi/Mosquitto_Setup/" mdxType="Link">Mosquitto Setup</Link>
      </li>
    </ul>
    <h2 {...{
      "id": "openhab--node-red-tutorials",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#openhab--node-red-tutorials",
        "aria-label": "openhab  node red tutorials permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`OpenHAB & Node-RED Tutorials`}</h2>
    <ul>
      <li parentName="ul">
        <Link to="/Advanced_User/OpenHAB_Home_Automation/IP_Camera_Control/" mdxType="Link">IP Camera Control</Link>
      </li>
      <li parentName="ul">
        <Link to="/Advanced_User/openHABian_on_Raspberry_Pi/Projects/" mdxType="Link">Node-RED & OpenHAB Projects</Link>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      